<template>
  <!-- Take Screenshot Modal -->
  <a-modal
    id="devices-schedule-task-model"
    v-model:visible="showTakeScreenshotModal"
    title="Screenshots"
    width="1000px"
    centered
    :mask-closable="false"
    :ok-button-props="{
      id: 'dm-schedule-task-ok-btn',
      disabled: !item.serviceStatus,
    }"
    ok-text="Take Screenshot"
    :cancel-button-props="{
      id: 'dm-schedule-task-cancel-btn',
      style: { marginLeft: 'auto' },
    }"
    :confirm-loading="isLoadingForScreenshot"
    @ok="takeScreenshot"
    @cancel="closeDeviceScreenshotModal"
  >
    <device-screenshot ref="DeviceScreenshot" :item="item" />
  </a-modal>
  <!-- Stream Video Modal -->
  <a-modal
    id="devices-schedule-task-model"
    v-model:visible="showScheduleTaskModal"
    title="Schedule"
    destroy-on-close
    :mask-closable="false"
    :ok-button-props="{ id: 'dm-schedule-task-ok-btn' }"
    ok-text="Schedule"
    :cancel-button-props="{
      id: 'dm-schedule-task-cancel-btn',
      style: { marginLeft: 'auto' },
    }"
    @ok="handleScheduleForSelectedType"
    @cancel="closeScheduleTaskModal"
  >
    <ScheduleTask
      ref="ScheduleTask"
      :show-schedule-task-modal="showScheduleTaskModal"
      :record="item"
      :device-details="deviceDetails"
    />
  </a-modal>
  <a-modal
    v-model:visible="showStreamModal"
    :footer="null"
    width="1000px"
    centered
    destroy-on-close
    :mask-closable="false"
    :title="item.title"
    :get-popup-container="() => document.getElementById('stream-modal-body')"
    @cancel="handleStreamModalClose"
  >
    <div id="stream-modal-body" class="w-100">
      <VideoStream
        v-if="streamSignal === true"
        :serial-number="item.Serial_number"
        :device-setting="item.id"
        :device-settings="item"
        @closeModal="showStreamModal = false"
      />

      <screen-shot
        v-else
        :snap-shot="snapShot"
        :device-setting-url="deviceSettingUrl"
        :serial-number="serialNumber"
        :is-default-image="isDefaultImage"
        :task_list="task_list"
        :device-cropping-params="deviceSetting.cropping_params"
        :device-id="deviceSetting.id"
        :device-camera-mode="deviceSetting.mode"
        :task-cropping-params="currentTask.cropping_params"
        :current-selected-task="currentTask.id"
        @setCameraMode="setCurrentCameraMode"
        @changeCroppingSignal="(val) => (croppingSignal = val)"
        @setControlImageTime="setControlImageTime"
      />
    </div>
  </a-modal>

  <!-- deviceName edit -->
  <a-modal
    v-model:visible="isEditDeviceName"
    width="400px"
    centered
    destroy-on-close
    :mask-closable="false"
    :title="ItemDeepCopy.title"
    :get-popup-container="() => document.getElementById('stream-modal-body')"
    ok-text="Update Device Name"
    @cancel="isEditDeviceName = false"
    @ok="handleSaveDeviceName"
  >
    <div>
      <a-input
        v-model:value="ItemDeepCopy.display_name"
        placeholder="Device Name"
        size="small"
        style="width: 100%"
      />
    </div>
  </a-modal>

  <!-- Edit Email -->

  <a-modal
    v-model:visible="isEditEmail"
    width="400px"
    centered
    destroy-on-close
    :mask-closable="false"
    :title="ItemDeepCopy.title || ''"
    :get-popup-container="() => document.getElementById('stream-modal-body')"
    @cancel="isEditEmail = false"
    @ok="handleSaveEmail"
    ok-text="Update Email"
  >
    <div>
      <a-input
        v-model:value="ItemDeepCopy.email"
        placeholder="Email"
        size="small"
        style="width: 100%"
      />
    </div>
  </a-modal>

  <a-drawer
    v-model:visible="showDrawer"
    width="850"
    placement="right"
    :title="ItemDeepCopy?.title || 'here'"
    :closable="true"
    @close="closeDrawer()"
  >
    <template #extra>
      <div
        class="drawer-dropdown-container cursor-pointer flex justify-content-center align-items-center"
        @click="handleOpenDrawerOptions"
      >
        <StarFilled
          v-if="ItemDeepCopy?.starred"
          style="font-size: 1em"
          count="1"
          class="starred"
        />
        <StarFilled
          v-else
          style="font-size: 1em"
          count="1"
          class="option-star"
        />
        &nbsp;
        <DownOutlined />
        <div v-if="openDrawerOptions" class="drawer-dropdown">
          <div class="px-3 mt-2">Move to...</div>
          <ul style="list-style: none; margin: 0; padding: 0px">
            <li
              v-if="!ItemDeepCopy?.starred"
              style="padding: 5px; width: 100%"
              @click="handleChangeStarred"
              class="cursor-pointer px-3 list-options"
            >
              <StarOutlined /> Starred
            </li>
            <li
              v-else
              style="padding: 5px; width: 100%"
              @click="handleChangeStarred"
              class="cursor-pointer px-3 list-options"
            >
              Un Starred
            </li>
          </ul>
        </div>
      </div>
    </template>

    <a-descriptions title="User Info" bordered>
      <a-descriptions-item :span="1" label="Serial Number">{{
        ItemDeepCopy?.Serial_number
      }}</a-descriptions-item>
      <a-descriptions-item :span="2" label="Last Active">{{
        ItemDeepCopy?.last_Active
      }}</a-descriptions-item>
      <a-descriptions-item :span="1" label="Version">{{
        ItemDeepCopy?.version_no
      }}</a-descriptions-item>
      <a-descriptions-item :span="2" label="OS Info">{{
        ItemDeepCopy?.os_info
      }}</a-descriptions-item>
      <a-descriptions-item :span="1" label="RAM">
        {{ ItemDeepCopy?.ram }}
      </a-descriptions-item>
      <a-descriptions-item :span="2" label="GPU">{{
        ItemDeepCopy?.gpu
      }}</a-descriptions-item>
    </a-descriptions>
    <DeviceDetail v-if="showDrawer" @updateListData="updateListData" />
  </a-drawer>

  <!--"Serial_number"-->
  <span v-if="column.dataIndex == 'device_name'" class="black-color list-cell">
    <a-popover arrow hover placement="top">
      <div
        v-if="!isEditDeviceName"
        :class="{ disabled: !item.active }"
        class="display-name"
      >
        {{
          item.title.length > 40 ? item.title.slice(0, 40) + '...' : item.title
        }}
        <sup v-if="item.starred">
          <a-rate
            style="font-size: 12px"
            :value="item?.starred"
            count="1"
            class=""
          />
        </sup>
      </div>
    </a-popover>
  </span>

  <!--  "Current_task_running",-->
  <span v-if="column.dataIndex == 'current_task'">
    <a-space
      class="black-color list-cell my-2 flex justify-content-center align-items-center"
    >
      <div
        v-if="item.manage"
        :color="isActive && item.Task ? 'processing' : 'default'"
      >
        <a-tooltip :title="item.Task ? showTaskName(item.Task) : 'None'">
          <a-tag
            v-if="showTaskName(item.Task) && isActive"
            color="blue"
            :disabled="true"
            >{{ showTaskName(item.Task) }}</a-tag
          >
          <a-tag
            v-else-if="showTaskName(item.Task) && !isActive"
            style="
              background-color: #fafafa;
              border-color: #dcdcdc;
              color: #808080;
            "
            :disabled="true"
            >{{ showTaskName(item.Task) }}</a-tag
          >
          <span v-else class="text-light">None</span>
        </a-tooltip>
      </div>
    </a-space>
  </span>

  <span v-if="column.dataIndex == 'traceOff'">
    <a-space
      v-if="isActive"
      style="
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      "
      class="w-100"
    >
      <span
        v-if="
          item.isInferenceRunning || item.recordInference || item.record_shift
        "
        class="flex justify-content-start align-items-center flex-wrap"
      >
        <div v-if="item.isInferenceRunning && item.recordInference">
          <a-tag color="success"> Guidance </a-tag>
          <a-tag color="success"> Trace </a-tag>
        </div>
        <a-tag v-else-if="item.isInferenceRunning" color="blue" class="mt-2">
          Guidance
        </a-tag>
        <a-tag v-else-if="item.recordInference" color="blue" class="mt-2">
          Trace
        </a-tag>
        <a-tag v-else-if="item.record_shift" color="success" class="mt-2">
          Recording
        </a-tag>
      </span>
      <span
        v-else
        style="width: 100%"
        class="flex justify-content-start align-items-center flex-wrap"
      >
        <a-tag
          style="width: 100%"
          color="default"
          class="flex justify-content-start align-items-center flex-wrap"
        >
          Idle
        </a-tag>
      </span>
    </a-space>
    <a-tag
      v-else
      style="background-color: #fafafa; border-color: #dcdcdc; color: #808080"
      :disabled="true"
      >Offline</a-tag
    >
  </span>

  <span v-if="column.dataIndex == 'activity'">
    <a-space
      style="
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      "
      class="w-100"
    >
      <div
        v-if="dataTransferDetails"
        style="width: 100%"
        class="d-flex justify-content-center align-items-center"
      >
        <a-tag
          style="display: flex; justify-content: center; align-items: center"
          class="mt-1 text-center"
        >
          <template #icon>
            <span v-if="dataTransferDetails?.progress > 0">
              {{ dataTransferDetails.progress + '%' }}
            </span>
          </template>
          &nbsp;{{ dataTransferDetails.transferType }}
        </a-tag>
        <a-tag
          style="display: flex; justify-content: center; align-items: center"
          class="mt-1 text-center"
        >
          <template #icon>
            <span v-if="dataTransferDetails?.progress > 0">
              {{ dataTransferDetails.name }}
            </span>
          </template>
        </a-tag>
      </div>
      <a-tag
        style="background-color: #fafafa; border-color: #dcdcdc; color: #808080"
        :disabled="true"
        v-else
      >
        None
      </a-tag>
    </a-space>
  </span>

  <!--  "updateDevice"-->
  <span v-if="column.dataIndex == 'actions'">
    <a-space>
      <a-tooltip v-if="!showShutDown && showStart" title="Start Device">
        <i
          class="bi bi-power image-icon"
          @click="pathfinderService(item, 'start_software')"
        />
      </a-tooltip>

      <a-tooltip
        v-if="item.serviceStatus && item.active && showShutDown"
        title="Stop Device"
      >
        <i
          class="bi bi-stop-circle image-icon"
          :class="{ disabled: !item.active }"
          @click="pathfinderService(item, 'shutdown_software')"
        />
      </a-tooltip>

      <a-tooltip title="Restart Device">
        <i
          class="bi bi-arrow-counterclockwise image-icon"
          :class="{ disabled: !item.active }"
          @click="pathfinderService(item, 'restart_software')"
        />
      </a-tooltip>

      <a-tooltip title="Show Live stream">
        <i
          class="bi bi-cast image-icon"
          :class="{ disabled: !isActive || isEditDeviceName || isEditEmail }"
          @click="showStream"
        />
      </a-tooltip>

      <a-tooltip title="Edit device name">
        <i
          class="bi bi-pencil-square image-icon"
          :class="{ disabled: !isActive || isEditDeviceName }"
          @click="handleSaveDeviceName({ ...item })"
        />
      </a-tooltip>

      <a-tooltip title="Edit Email">
        <mail-outlined
          style="margin-right: 0.3px"
          :class="{ disabled: !isActive || isEditEmail }"
          :style="{ color: isActive ? '#2391ff' : 'lightgray' }"
          @click="handleSaveEmail({ ...item })"
        />
      </a-tooltip>

      <a-tooltip title="Edit">
        <span>
          <setting-outlined
            :class="{ disabled: !isActive }"
            :style="{ color: isActive ? '#2391ff' : 'lightgray' }"
            @click="handleDeviceDetailDrawer({ ...item })"
          />
        </span>
      </a-tooltip>
      <a-tooltip title="Delete">
        <a-popconfirm
          title="Are you sure? you want to Delete this device?"
          ok-text="Yes"
          cancel-text="No"
          @confirm="deleteDevice()"
        >
          <span class="cursor-pointer">
            <delete-outlined
              :class="{ disabled: !isActive }"
              :style="{ color: 'red' }"
            />
          </span>
        </a-popconfirm>
      </a-tooltip>
    </a-space>
  </span>
</template>

<script>
import DeviceService from 'src/services/device.js';
import dateHelper from '../../../shared/Helpers/dateHelper';

import {
  DeleteOutlined,
  DownOutlined,
  MailOutlined,
  SettingOutlined,
  StarFilled,
  StarOutlined,
} from '@ant-design/icons-vue';
import spaceMixin from 'src/mixins/handleSpace';
import { getSortedTask } from 'src/utils/task';
import { mapActions, mapGetters } from 'vuex';
import DeviceDetail from './DeviceDetailPage/DeviceDetailPage.vue';
import ScheduleTask from './ScheduleTask.vue';
import ScreenShot from './ScreenShot.vue';
import VideoStream from './VideoStream.vue';

import featureAuthorization from 'src/mixins/featureAuthorization';
import fcm from 'src/services/fcm';
import DeviceScreenshot from './DeviceScreenshot.vue';

export default {
  name: 'AssignedDevice',
  components: {
    VideoStream,
    ScreenShot,
    ScheduleTask,
    DeviceScreenshot,
    DeviceDetail,
    DownOutlined,
    StarOutlined,
    MailOutlined,
    SettingOutlined,
    DeleteOutlined,
    StarFilled,
  },
  mixins: [spaceMixin, featureAuthorization],
  inject: ['toast', 'isDev'],
  props: [
    'currentTaskId',
    'item',
    'index',
    'tableRowColumns',
    'deviceHoverInfo',
    'isLoading',
    'task_list',
    'trainedTasks',
    'activeDevices',
    'allDevices',
    'column',
    'taskIdToTaskNameMap',
  ],
  emits: [
    'unAssignDeviceFromDept',
    'updateDeviceList',
    'deleteDeviceFromDeviceList',
    'isModalOpen',
    'closeModal',
  ],

  data() {
    return {
      showStart: false,
      showShutDown: false,
      deviceDetails: {},
      isEditDeviceName: false,
      isEditEmail: false,
      deviceSetting: {},
      oldDeviceSetting: {},
      currentTask: {},
      isTaskUpdating: false,
      pollingTime: 6000,
      polling: null,
      start_time: '00:00:00',
      end_time: '23:59:59',
      tempDeviceSetting: {},
      isRequestResolved: true,
      date: new Date(),
      currentTime: null,
      currentFormattedDate: null,
      analytics_start_time: '00:00:00',
      analytics_end_time: '23:59:59',
      showMoreOptions: false,
      // ================ device status ================
      serialNumber: '',
      deviceSettingUrl: '',
      image: '',
      isDeviceStatusPolled: false,
      deviceStatusPolling: null,
      deviceStatus: ['Inactive'],
      isDownloading: false,
      isUploading: false,
      progress: 0,
      deviceCroppingParams: {},
      controlTime: '',
      showTowerLightModal: false,
      // ========= snapshot
      showStreamModal: false,
      streamSignalPolling: null,
      streamSignal: false,
      croppingSignal: false,
      currentCameraMode: '',
      isDefaultImage: false,
      snapShot: null,
      downloadUploadDetails: '',
      openRestartDeviceDialog: true,
      openUnassignDialog: true,
      deviceToUnassign: '',
      isStreamLoading: false,
      rtcServerThreadStatus: false,
      projector: false,
      isUnmounted: false,
      isApplyingChanges: false,
      version_no: '',
      isModelToastShown: false,
      oldDisplayName: '',
      oldEmail: '',
      showScheduleTaskModal: false,
      showTakeScreenshotModal: false,
      isLoadingForScreenshot: false,
      isDeletingDevice: false,
      // Feature based states
      showStepImageControl: false,
      showTaskStepsControl: false,
      showFPSCapControl: false,
      deviceInfo: null,

      // drawer
      showDrawer: false,
      ItemDeepCopy: { ...this.item },

      // emailModal
      openDrawerOptions: false,
    };
  },

  computed: {
    ...mapGetters(['organization']),
    getDeviceStatus() {
      const {
        is_shift_managed,
        record_shift,
        isInferenceRunning,
        inference,
        recording,
        task,
        active,
      } = this.item;
      if (is_shift_managed) {
        if (inference || recording)
          return task ? task : this.item?.Task?.taskName;
      }
      if (record_shift || isInferenceRunning)
        return task ? task : this.item?.Task?.taskName;
      return active ? 'Active' : 'Inactive';
    },

    deviceStatusColor() {
      return {
        Active: 'processing',
        Inactive: 'default',
      }[this.getDeviceStatus];
    },

    deviceActivity() {
      const {
        is_shift_managed,
        record_shift,

        inference,
        recording,
      } = this.item;
      if (is_shift_managed) {
        if (inference) return 'Inferencing for ';
        if (recording) return 'Recording for ';
      }
      if (inference) return 'Inferencing for ';
      if (record_shift) return 'Recording for ';
      return '';
    },

    dataTransferDetails() {
      const { downloading, uploading } = this.item;
      if (downloading)
        return this.getDataTransferTypeDetails(downloading, 'Downloading');

      if (uploading)
        return this.getDataTransferTypeDetails(uploading, 'Uploading');

      return false;
    },

    taskOptions() {
      return getSortedTask(this.task_list);
    },

    isActive() {
      return !!this.item.active;
    },

    isControlsDisabled() {
      return !this.isActive || !this.deviceDetails.manage;
    },
  },

  watch: {
    item: {
      handler: function (newVal) {
        // this.ItemDeepCopy = {...newVal}
        this.setDeviceStatusState({ ...newVal });
        // this.getDeviceSettings();
      },
      deep: true,
    },
  },
  async created() {
    window.addEventListener('beforeunload', this.resetPolling);
    document.addEventListener('click', this.handleDrawerDropdown);
    if (this.item.active) {
      const serialNumberToSearch = this.item.Serial_number;
      const matchingDevice = this.allDevices.find(
        (device) => device.Serial_number === serialNumberToSearch
      );

      if (this.item.active && ('Task' in this.item || this.item.Task)) {
        this.deviceDetails = {
          ...this.item,
          ...(matchingDevice || {}),
        };
      }
    } else {
      this.deviceDetails = {
        ...this.item,
        shift_start_time: this.item.shift_start_time
          ?.split('T')
          .pop()
          .replace('Z', ''),
        shift_end_time: this.item.shift_end_time
          ?.split('T')
          .pop()
          .replace('Z', ''),
      };
    }

    if (
      this.deviceDetails.Task &&
      typeof this.deviceDetails.Task === 'object'
    ) {
      const tempTask = this.deviceDetails.Task.id;
      delete this.deviceDetails.Task;
      this.deviceDetails['Task'] = tempTask;
    }
    if (this.deviceDetails.active) {
      this.showShutDown = true;
    }
    if (this.deviceDetails.serviceStatus) {
      this.showStart = true;
    }
  },

  beforeUnmount() {
    this.isUnmounted = true;
    this.resetPolling();
    document.removeEventListener('click', this.handleDrawerDropdown);
  },

  methods: {
    ...mapActions(['setDeviceForDetail']),

    /**
     * Handles the click event on the drawer dropdown.
     * If the drawer is open and the drawer options are open, and the clicked element is not part of the drawer dropdown, the drawer options are closed.
     *
     * @param {Event} event - The click event object.
     */
    handleDrawerDropdown(event) {
      const element = event.target; // Get the clicked element

      if (element) {
        if (
          this.showDrawer &&
          this.openDrawerOptions &&
          !element.className.includes('drawer-dropdown')
        ) {
          this.openDrawerOptions = false;
        }
      }
    },

    handleOpenDrawerOptions() {
      this.openDrawerOptions = !this.openDrawerOptions;
    },
    async deleteDevice() {
      try {
        this.isDeletingDevice = true;
        const serialNumber = this.ItemDeepCopy.Serial_number;

        // await this.performDeviceDeletion(serialNumber);
        const [error] = await DeviceService.deleteDevice(serialNumber, false);

        if (error)
          this.toast.error('Error while deleting Device! Please try again.');
        else {
          await this.emitDeviceDeletedEvent(serialNumber);
          this.toast.success('Device deleted successfully!');
        }
      } catch (e) {
        console.error('An error occurred:', e);
      } finally {
        this.isDeletingDevice = false;
      }
    },

    async emitDeviceDeletedEvent(serialNumber) {
      try {
        await this.$emit('deleteDeviceFromDeviceList', serialNumber);
      } catch (error) {
        console.error('Error emitting device deletion event:', error);
      }
    },

    updateListData(data) {
      this.$emit('updateDeviceList', data);
    },

    closeDrawer() {
      this.setDeviceForDetail({});
      this.showDrawer = false;
    },

    async handleChangeStarred() {
      const payload = {
        ...this.ItemDeepCopy,
        starred: !this.ItemDeepCopy.starred,
      };
      this.$emit('updateDeviceList', {
        ...payload,
      });

      await DeviceService.updateDevice(
        this.ItemDeepCopy.Serial_number,
        payload,
        false
      );
      this.ItemDeepCopy.starred = payload.starred;
      this.openDrawerOptions = false;
    },

    handleDeviceDetailDrawer(device) {
      if (!this.isActive) return;
      /**
       * Sets the device for detail view and opens the device detail drawer.
       *
       * @param {Object} device - The device object to set for detail view.
       */

      this.setDeviceForDetail(device);
      this.handleOpenDrawer(device);
    },

    showTaskName(task) {
      if (task && typeof task === 'object') {
        return task.taskName;
      }
      return this.taskIdToTaskNameMap[task]
        ? this.taskIdToTaskNameMap[task]
        : '';
    },

    async takeScreenshot() {
      this.isLoadingForScreenshot = true;

      // Call pathfinderService with 'take_screenshot' signal
      await this.pathfinderService(this.item, 'take_screenshot');

      // Reset loading state
      this.isLoadingForScreenshot = false;
    },
    async pathfinderService(record, signal) {
      if (signal === 'take_screenshot') this.isLoadingForScreenshot = true;
      if (signal === 'shutdown_software') {
        this.showStart = true;
        this.showShutDown = false;
      }
      const payload = {
        data: {},
        type: signal,
        device_id: record.Serial_number,
        //this.org-MAC-service
      };
      const [error] = await fcm.sendMessageToSQS(payload, false);
      this.isLoadingForScreenshot = false;
      if (error) throw 'Error while sending set telemetry request';
    },
    closeScheduleTaskModal() {
      this.showScheduleTaskModal = false;
      this.$emit('closeModal', false);
    },
    closeDeviceScreenshotModal() {
      this.showTakeScreenshotModal = false;
      this.$emit('closeModal', false);
    },
    getDataTransferTypeDetails(typeObj, type) {
      return {
        name: typeObj.name,
        progress: typeObj.progress,
        transferType: type,
      };
    },

    setControlImageTime() {
      const date = new Date();
      this.controlTime =
        date.getHours() +
        ':' +
        date.getMinutes() +
        ':' +
        date.getSeconds() +
        'Z';
      this.updateDevice();
    },

    setDeviceStatusState(device_status) {
      const {
        downloading,
        active,
        uploading,
        inference,
        recording,
        task,
        image,
        answer,
        version_no,
        rtc_thread_status,
        is_projector_attached,
      } = device_status;
      if (inference) {
        this.deviceStatus = ['Inferencing for ', task];
      } else if (recording) {
        this.deviceStatus = ['Recording for ', task];
      } else if (active) {
        this.deviceStatus = ['Active'];
      } else {
        this.deviceStatus = ['Inactive'];
      }
      if (version_no) {
        this.version_no = version_no;
      }
      this.isDownloading = !!downloading;
      this.isUploading = !!uploading;
      this.snapShot = image ? 'data:image/jpg;base64, ' + image : false;
      this.isDefaultImage = answer;
      let progress, name;
      if (downloading) {
        progress = downloading.progress;
        name = downloading.name;
        if (name.endsWith('.zip') && !this.isModelToastShown) {
          this.isModelToastShown = true;
          this.toast.info(
            `We are downloading the model on your device. This will take several minutes.`
          );
        }
      }
      if (uploading) {
        progress = uploading.progress;
        name = uploading.name;
      }
      this.downloadUploadDetails = name;
      this.progress = progress ? progress : 0;

      if (rtc_thread_status) {
        this.rtcServerThreadStatus = rtc_thread_status;
      }

      if (this.currentTaskId !== this.deviceDetails.Task && this.item.active) {
        this.deviceDetails.Task = this.currentTaskId;
        this.deviceDetails.isInferenceRunning = false;
        this.deviceDetails.recordInference = false;
      }
      this.projector = is_projector_attached;
    },

    setCurrentCameraMode(value) {
      this.deviceDetails.mode = value;
      this.updateDevice();
    },

    isTrainedTask(taskId = this.item.Task) {
      return !this.trainedTasks.map(({ task }) => task.id).includes(taskId);
    },

    isTaskChanged() {
      if (typeof this.deviceDetails.Task == 'object') {
        return this.item?.Task?.id !== this.deviceDetails.Task?.id;
      }
      if (typeof this.item.Task == 'object') {
        return this.item.Task?.id !== this.deviceDetails?.Task;
      }
      return this.item?.Task !== this.deviceDetails?.Task;
    },

    validateRecordShift() {
      const { record_shift, isInferenceRunning } = this.deviceDetails;
      if ((record_shift || isInferenceRunning) && this.isTaskChanged()) {
        this.renderErrorToast(
          'You cannot start inference, manage shift or change task when recording shift.'
        );
        return false;
      }

      return true;
    },

    validateRecordInference() {
      const {
        is_shift_managed,
        recordInferenceFlipped,
        isInferenceRunning,
        isShiftTime,
      } = this.getDeviceStates();

      if (is_shift_managed && recordInferenceFlipped && isShiftTime)
        return this.renderErrorToast(
          'Recording cannot be toggled while Manage Shift is enabled. Please disable Manage Shift first.'
        );

      if (
        recordInferenceFlipped &&
        isInferenceRunning &&
        !this.deviceDetails.is_shift_managed
      )
        return this.renderErrorToast(
          'Recording cannot be toggled while Inference is running. Please stop Inference first.'
        );

      return true;
    },

    getDeviceStates() {
      return {
        is_shift_managed: this.isDeviceOn('is_shift_managed'),
        recordInferenceFlipped: this.isControlFlipped('recordInference'),
        isInferenceRunning: this.isDeviceOn('isInferenceRunning'),
        isShiftTime: this.isShiftTime(),
      };
    },

    renderErrorToast(message) {
      const timeout = { timeout: 2000 };
      this.toast.info(message, timeout);
      return false;
    },

    isDeviceOn(deviceProp) {
      const oldSettings = this.item;
      const newSettings = this.deviceDetails;

      const oldS = oldSettings[deviceProp];
      const newS = newSettings[deviceProp];
      return oldS === true && newS === true;
    },

    isControlFlipped(deviceProp) {
      const oldSettings = this.item;
      const newSettings = this.deviceDetails;

      const oldS = oldSettings[deviceProp];
      const newS = newSettings[deviceProp];
      return !(oldS === newS);
    },

    isShiftTime() {
      if (!this.item.shift_start_time || !this.item.shift_end_time) {
        return false;
      }
      let prevStartShiftTime = new Date(
        this.item.shift_start_time.slice(0, -1)
      ).getTime();
      let prevEndShiftTime = new Date(
        this.item.shift_end_time.slice(0, -1)
      ).getTime();
      let currentTime = new Date().getTime();
      return prevStartShiftTime < currentTime && currentTime < prevEndShiftTime;
    },

    setupUpdateDeviceSetting() {
      this.date = new Date();
      this.currentFormattedDate = dateHelper.getFormattedDate(this.date);
      if (!this.controlTime) this.controlTime = '00:00:00Z';
      let data = {
        blurFace: this.deviceDetails.blurFace,
        depth_cam_enabled: this.deviceDetails.depth_cam_enabled,
        isInferenceRunning: this.deviceDetails.isInferenceRunning,
        manage: this.deviceDetails.manage,
        recordInference: this.deviceDetails.recordInference,
        is_shift_managed: this.deviceDetails.is_shift_managed,
        record_shift: this.deviceDetails.record_shift,
        control_image_time: this.currentFormattedDate + 'T' + this.controlTime,
        mode: this.currentCameraMode,
        multi_view: this.deviceDetails.multi_view,
        is_translation_enabled: this.deviceDetails.is_translation_enabled,
        projector_enable: this.deviceDetails.projector_enable,
        remote_cam_enabled: this.deviceDetails.remote_cam_enabled,
        day_of_week_for_recording: this.deviceDetails.day_of_week_for_recording,
        box_visualization: this.deviceDetails.box_visualization,
        show_previous_cycle_errors:
          this.deviceDetails.show_previous_cycle_errors,
        enable_video: this.deviceDetails.enable_video,
        show_step_images: this.deviceDetails.show_step_images,
        show_task_definition: this.deviceDetails.show_task_definition,
        auto_update_enabled: this.deviceDetails.auto_update_enabled,
        upload_bad_cycle_videos: this.deviceDetails.upload_bad_cycle_videos,
        fps_cap: this.deviceDetails.fps_cap,
      };

      data = Object.entries(data)
        .filter(([key, val]) => this.item[key] !== val)
        .reduce((res, el) => {
          res[el[0]] = el[1];
          return res;
        }, {});

      if (this.isTaskChanged()) {
        data['Task'] = this.deviceDetails.Task;
        if (this.isTrainedTask(this.deviceDetails.Task))
          data['isInferenceRunning'] = false;
        this.isTaskUpdating = true;
      }
      return JSON.stringify(data);
    },

    async updateDevice() {
      this.isApplyingChanges = true;
      if (!this.validateRecordInference()) {
        this.isApplyingChanges = false;
        return;
      }

      if (!this.validateRecordShift()) {
        this.isApplyingChanges = false;
        return;
      }

      await this.handleUpdateDevice();
      this.isTaskUpdating = false;
      this.isApplyingChanges = false;
    },

    SendDeviceUpdate(requestPayload, toast = true) {
      return new Promise(async (resolve) => {
        const [error, data] = await DeviceService.updateDeviceSettings(
          this.organization,
          this.item.id,
          requestPayload,
          false
        );
        if (error) {
          this.renderErrorToast('Failed to update device setting');
          resolve(false);
        } else if (toast) {
          this.toast.success(
            'We are applying your changes. This should take a minute.'
          );
        }
        resolve(data || {});
      });
    },

    async handleUpdateDevice(showToast = true) {
      const requestPayload = this.setupUpdateDeviceSetting();
      if (Object.keys(JSON.parse(requestPayload)).length === 0) {
        // this.toast.error('No changes to apply');
        return;
      }
      const updatedDeviceSetting = await this.SendDeviceUpdate(
        requestPayload,
        showToast
      );
      if (!updatedDeviceSetting) return;
      updatedDeviceSetting['Task'] = updatedDeviceSetting?.Task?.id;
      const deviceInfo = updatedDeviceSetting.Device;
      delete updatedDeviceSetting.Device;
      this.$emit('updateDeviceList', {
        ...deviceInfo,
        ...updatedDeviceSetting,
        type: true,
      });
    },

    showStream() {
      this.showStreamModal = true;
      this.streamSignal = true;
      this.$emit('isModalOpen', true);
    },
    handleStreamModalClose() {
      this.streamSignal = false;
      this.$emit('closeModal', false);
      this.resetStreamPolling();
    },

    async handleSaveDeviceName(item) {
      this.isEditEmail = false;
      if (!this.isEditDeviceName) {
        this.isEditDeviceName = true;
        this.oldDisplayName = this.item.display_name;
        this.ItemDeepCopy = item;
        return;
      }
      this.$emit('updateDeviceList', this.ItemDeepCopy);
      const [error] = await DeviceService.updateDevice(
        this.ItemDeepCopy.Serial_number,
        this.ItemDeepCopy,
        false
      );
      if (error) {
        this.toast.error('Unable to update the name!');
        this.$emit('updateDeviceList', {
          ...this.ItemDeepCopy,
          display_name: this.oldDisplayName,
        });
      }
      this.isEditDeviceName = false;
    },
    async handleOpenDrawer(item) {
      if (!this.showDrawer) {
        this.showDrawer = true;
        this.ItemDeepCopy = item;
        // this.oldEmail = this.item.email;
        return;
      }
      this.showDrawer = false;
      return;
    },
    async handleSaveEmail(item) {
      this.isEditDeviceName = false;
      if (!this.isEditEmail) {
        this.isEditEmail = true;
        this.oldEmail = this.item.email;
        this.ItemDeepCopy = item;
        return;
      }
      this.$emit('updateDeviceList', this.deviceDetails);
      const [error] = await DeviceService.updateDeviceSettings(
        this.organization,
        this.item.id,
        { email: this.ItemDeepCopy.email },
        false
      );
      if (error) {
        this.toast.error('Unable to update the name!');
        this.$emit('updateDeviceList', {
          ...this.deviceDetails,
          display_name: this.oldEmail,
        });
      }
      this.isEditEmail = false;
    },

    resetPolling() {
      clearTimeout(this.polling);
      this.resetDeviceStatusPolling();
      clearInterval(this.streamSignalPolling);
    },

    resetDeviceStatusPolling() {
      clearTimeout(this.deviceStatusPolling);
    },

    resetStreamPolling() {
      clearInterval(this.streamSignalPolling);
    },

    handleScheduleForSelectedType() {
      this.$refs.ScheduleTask.handleScheduleForSelectedType();
    },

  },
};
</script>

<style scoped>
.drawer-dropdown-container {
  position: relative;
  border: 1px solid gray;
  border-radius: 3px;
  width: 45px;
  height: 30px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.list-options {
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) ease-in-out;
  color: rgb(128, 128, 128);
  font-size: 14px;
  font-weight: 600;
}

.list-options-active {
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) ease-in-out;
  color: #f9da14;
  font-size: 14px;
  font-weight: 600;
}

.list-options:hover,
.list-options-active:hover {
  color: rgb(128, 128, 128);
  background-color: rgb(250, 250, 250);
}

.option-star {
  color: gray;
}

.starred {
  color: #f9da14 !important;
}

.drawer-dropdown {
  position: absolute;
  top: calc(100% + 5px);
  right: 0;
  width: 250px;
  height: 60px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  font-size: 11px;
  font-weight: 700;
  background: white;
  z-index: 99;
}

.font-semibold {
  font-weight: 600;
}
label {
  font-size: 13px;
}

.image-icon {
  cursor: pointer;
  color: #2391ff;
}

.disabled {
  pointer-events: none;
  color: lightgray;
}

.image-icon:hover {
  color: #34495e;
  transform: scale(1.1, 1.1);
}

.display-name {
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
}

.custom-control-label {
  white-space: nowrap;
  font-size: 12px;
}

td {
  padding: 0.5em;
}

.download-upload-details {
  white-space: nowrap;
  width: 10em;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  text-align: center;
}

.text-light {
  color: gray !important;
}

/* .stream-modal-body .ant-modal-body {
    padding: 0 12px !important;
  } */
</style>
