<template>
  <div
    class="d-flex flex-wrap"
    :loading="isLoading"
    style="max-width: 100%; max-height: 100%;"
  >
    <div class="d-flex" style="width: 500px;">
      <a-select
        v-model:value="selectedTime"
        class="w-100"
        show-search
        placeholder="Select Time"
        :loading="isFetchingScreenshotsIntervals"
        style="width: 500px;"
        @change="handleTimeChange"
      >
        <a-select-option
          v-for="option in formattedIntervals"
          :key="option.original"
          :loading="isFetchingScreenshotsIntervals"
          :value="option.original"
          :label="option.formatted"
          >{{ option.formatted }}</a-select-option
        >
      </a-select>
      <a-button
        class="ml-2"
        :disabled="isFetchingScreenshotsIntervals"
        @click="handleRefresh"
      >
        <ReloadOutlined />
      </a-button>
    </div>
    <a-divider />
    <a-card
      :loading="isImagesLoading"
      v-for="(file, index) in paginatedFiles"
      :key="index"
      class="image-card"
      size="large"
      :body-style="cardBodyStyle"
    >
      <img
        v-if="file"
        :src="file.presignedUrl"
        alt="Screenshot"
        @click="zoomImage(file)"
      />

      <!-- <template #extra>
        <a-space>
          <a-tooltip title="Reload Images">
            <a-button @click="refreshImages" type="default" size="small">
              <template #icon>
                <reload-outlined />
              </template>
            </a-button>
          </a-tooltip>
        </a-space>
      </template>

      <template #actions>
        
      </template> -->
    </a-card>
    <!-- Modal for zooming image -->
    <a-modal
      v-if="zoomedImage"
      :visible="zoomModalVisible"
      @cancel="closeZoomModal"
      footer=""
      :width="zoomModalWidth"
    >
      <img
        :src="zoomedImage.presignedUrl"
        alt="Zoomed Screenshot"
        style="width: 98%; height: 98%; object-fit: contain;"
      />
    </a-modal>
    <a-divider />
    <!-- <a-pagination
      v-if="numPages >= 1"
      :current="currentPage"
      :total="totalNumberOfPages * itemsPerPage"
      :page-size="itemsPerPage"
      @change="handlePageChange"
    /> -->
  </div>
</template>

<script>
import { ReloadOutlined } from '@ant-design/icons-vue';
import DeviceService from 'src/services/device.js';
import httpClient from 'src/service/httpClient.js';

export default {
  name: 'DeviceScreenshot',
  components: {
    ReloadOutlined
  },
  props: ['item'],

  data() {
    return {
      files: [],
      currentPage: 1,
      itemsPerPage: 6, // Adjust this based on your preference
      isLoading: true,
      isImagesLoading: true,
      zoomModalVisible: false,
      zoomedImage: null,
      zoomModalWidth: 800,
      totalNumberOfPages: 1,
      selectedTime: null,
      selectedTimeFormatted: null,
      intervals: [],
      isFetchingScreenshotsIntervals: true,
      bucket_name: null
    };
  },

  computed: {
    formattedIntervals() {
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      console.log(userTimeZone);

      return this.intervals.map(interval => {
        const utcDate = new Date(`${interval}Z`);
        return {
          original: interval,
          formatted: utcDate.toLocaleString('en-US', {
            timeZone: userTimeZone,
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: '2-digit',
            second: '2-digit'
          })
        };
      });
    },
    paginatedFiles() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      console.log(this.files.slice(startIndex, endIndex), startIndex, endIndex);
      return this.files.slice(0, this.itemsPerPage);
    },

    cardBodyStyle() {
      return {
        width: '100%',
        flexGrow: '2',
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden'
      };
    },

    numPages() {
      return Math.ceil(this.files.length / this.itemsPerPage);
    }
  },

  watch: {
    async visible() {
      await this.getDeviceScreenshots();
    },
    currentPage() {
      this.getDeviceScreenshots();
    },
    selectedTime(value) {
      this.getDeviceScreenshotsForTime();
    }
  },

  async created() {
    // await this.getDeviceScreenshots();
    await this.getScreenshotsTime();
  },

  methods: {
    handleRefresh() {
      this.getScreenshotsTime();
    },
    showModal() {
      // this.showScheduleModalAnalytics(this.record);
      return this.showScheduleTaskModal;
    },
    closeModal() {
      this.$emit('toggleDeviceScreenshotModal');
      return;
    },
    handleTimeChange(value) {
      console.log(value);
      const date = new Date(value);

      // Extract individual components
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date
        .getDate()
        .toString()
        .padStart(2, '0');
      const hours = date
        .getHours()
        .toString()
        .padStart(2, '0');
      const minutes = date
        .getMinutes()
        .toString()
        .padStart(2, '0');
      const seconds = date
        .getSeconds()
        .toString()
        .padStart(2, '0');

      // Formatted date string
      const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
      this.selectedTime = value;
      this.selectedTimeFormatted = formattedDate;
      console.log(this.selectedTime);
    },
    async getScreenshotsTime() {
      this.isFetchingScreenshotsIntervals = true;
      const [error, data] = await DeviceService.getDeviceScreenshotsIntervals(
        false,
        {
          device_id: this.item.Serial_number
        }
      );

      if (data) {
        this.intervals = data;
        this.isFetchingScreenshotsIntervals = false;
        return;
      }
      this.isFetchingScreenshotsIntervals = false;
    },
    async getDeviceScreenshots() {
      this.isLoading = true;
      const [error, data] = await DeviceService.getDeviceScreenshots(false, {
        device_id: this.item.Serial_number,
        page: this.currentPage,
        items_per_page: 6
      });

      if (!error) {
        this.files = data.files;
        this.totalNumberOfPages = data.num_pages;
        await this.generatePresignedUrls();
        this.isLoading = false;
      }
    },
    async getDeviceScreenshotsForTime() {
      console.log('getDeviceScreenshotsForTime');
      this.isLoading = true;
      const [error, data] = await DeviceService.getDeviceScreenshots(false, {
        device_id: this.item.Serial_number,
        time: this.selectedTimeFormatted
      });

      if (!error) {
        this.files = data.files;
        this.bucket_name = data?.bucket_name;
        // this.totalNumberOfPages = data.num_pages;
        await this.generatePresignedUrls();
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    async generatePresignedUrls() {
      const updatedFiles = [];
      this.isImagesLoading = true;
      for (const file of this.files) {
        const presignedUrl = await this.getPresignedUrl(file);
        updatedFiles.push({ path: file, presignedUrl });
        console.log(presignedUrl, updatedFiles);
      }

      this.files = updatedFiles;
      this.isImagesLoading = false;
    },

    async getPresignedUrl(file) {
      const { presigned_url } = await httpClient.post(
        'generic/generate_new_url/',
        {
          bucket_name: this.bucket_name
            ? this.bucket_name
            : `${localStorage.getItem('organization')}-training`,
          object_path: file
        },
        false,
        false,
        false
      );

      return presigned_url;
    },

    handlePageChange(page) {
      this.currentPage = page;
    },

    zoomImage(image) {
      this.zoomedImage = image;
      this.zoomModalVisible = true;
    },

    closeZoomModal() {
      this.zoomModalVisible = false;
      this.zoomedImage = null;
    },

    refreshImages() {
      // Implement image refreshing logic if needed
    }
  }
};
</script>

<style scoped>
.image-card {
  flex: 0 0 calc(30% - 16px); /* Adjust the width as needed, 4 images per row */
  margin: 8px; /* Adjust margin as needed for spacing */
}

.image-card img {
  width: 100%; /* Make the image fill the container width */
  height: 100%; /* Make the image fill the container height */
  object-fit: cover; /* Maintain aspect ratio and cover the container */
}

/* Override Ant Design modal styles to make it larger */
.ant-modal {
  max-width: 100%; /* Adjust the maximum width of the modal */
  max-height: 100%;
}

/* Override Ant Design modal content styles if needed */
.ant-modal-content {
  max-height: 100vh; /* Adjust the maximum height of the modal content */
  overflow-y: auto; /* Enable vertical scrolling if needed */
}
</style>
